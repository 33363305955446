var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "create-resell-ticket-modal",
      "size": "xl",
      "centered": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "header-bg-variant": "light-info",
      "hide-footer": "",
      "title": _vm.$t('resellTicket.form.title')
    },
    on: {
      "show": _vm.onLoad
    }
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    }
  }, [_c('validation-observer', {
    ref: "refFormObserver"
  }, [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('resellTicket.form.type'),
      "vid": "type",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.type')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          class: _vm.getValidationState(validationContext) === false ? 'v-select-is-invalid is-invalid' : '',
          attrs: {
            "id": "type",
            "placeholder": _vm.$t('resellTicket.form.ph.type'),
            "options": _vm.resellTypes,
            "disabled": "",
            "clearable": false,
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(_vm._s(_vm.$t('resellTicket.type.' + data.label)))])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('resellTicket.type.' + data.label)))])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: _vm.createRTReq.type,
            callback: function callback($$v) {
              _vm.$set(_vm.createRTReq, "type", $$v);
            },
            expression: "createRTReq.type"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('resellTicket.form.source')
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": _vm.$t('resellTicket.form.source'),
      "options": _vm.sources,
      "clearable": false,
      "disabled": ""
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.label) + " ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.createRTReq.source,
      callback: function callback($$v) {
        _vm.$set(_vm.createRTReq, "source", $$v);
      },
      expression: "createRTReq.source"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('saleReport.columns.airline')
    }
  }, [_c('v-select', {
    attrs: {
      "placeholder": _vm.$t('saleReport.columns.airline'),
      "options": _vm.airlineSupports,
      "reduce": function reduce(airline) {
        return airline.code;
      },
      "label": "code",
      "clearable": false,
      "rules": "required",
      "disabled": ""
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.name) + " (" + _vm._s(data.code) + ") ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.name) + " (" + _vm._s(data.code) + ") ")])];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.createRTReq.airlineCode,
      callback: function callback($$v) {
        _vm.$set(_vm.createRTReq, "airlineCode", $$v);
      },
      expression: "createRTReq.airlineCode"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('saleReport.columns.bookingCode'),
      "vid": "bookingCode",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('saleReport.columns.bookingCode')) + " "), _vm.createRTReq.source === _vm.mamaFullName ? _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")]) : _vm._e()];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          class: _vm.getValidationState(validationContext) === false ? 'v-select-is-invalid is-invalid' : '',
          attrs: {
            "id": "bookingCode",
            "placeholder": _vm.$t('saleReport.columns.bookingCode'),
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "clearable": false,
            "taggable": "",
            "multiple": "",
            "no-drop": ""
          },
          scopedSlots: _vm._u([{
            key: "search",
            fn: function fn(_ref) {
              var attributes = _ref.attributes,
                events = _ref.events;
              return [attributes.type === 'checkbox' ? _c('input', _vm._g(_vm._b({
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputPnr,
                  expression: "inputPnr"
                }],
                staticClass: "vs__search",
                attrs: {
                  "value": "",
                  "required": !_vm.pnrs,
                  "maxlength": "6",
                  "minlength": "6",
                  "type": "checkbox"
                },
                domProps: {
                  "checked": Array.isArray(_vm.inputPnr) ? _vm._i(_vm.inputPnr, "") > -1 : _vm.inputPnr
                },
                on: {
                  "input": function input(e) {
                    return _vm.handleDebounceBookingCode(e.target.value);
                  },
                  "change": function change($event) {
                    var $$a = _vm.inputPnr,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false;
                    if (Array.isArray($$a)) {
                      var $$v = "",
                        $$i = _vm._i($$a, $$v);
                      if ($$el.checked) {
                        $$i < 0 && (_vm.inputPnr = $$a.concat([$$v]));
                      } else {
                        $$i > -1 && (_vm.inputPnr = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
                      }
                    } else {
                      _vm.inputPnr = $$c;
                    }
                  }
                }
              }, 'input', attributes, false), events)) : attributes.type === 'radio' ? _c('input', _vm._g(_vm._b({
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputPnr,
                  expression: "inputPnr"
                }],
                staticClass: "vs__search",
                attrs: {
                  "value": "",
                  "required": !_vm.pnrs,
                  "maxlength": "6",
                  "minlength": "6",
                  "type": "radio"
                },
                domProps: {
                  "checked": _vm._q(_vm.inputPnr, "")
                },
                on: {
                  "input": function input(e) {
                    return _vm.handleDebounceBookingCode(e.target.value);
                  },
                  "change": function change($event) {
                    _vm.inputPnr = "";
                  }
                }
              }, 'input', attributes, false), events)) : _c('input', _vm._g(_vm._b({
                directives: [{
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputPnr,
                  expression: "inputPnr"
                }],
                staticClass: "vs__search",
                attrs: {
                  "value": "",
                  "required": !_vm.pnrs,
                  "maxlength": "6",
                  "minlength": "6",
                  "type": attributes.type
                },
                domProps: {
                  "value": _vm.inputPnr
                },
                on: {
                  "input": [function ($event) {
                    if ($event.target.composing) { return; }
                    _vm.inputPnr = $event.target.value;
                  }, function (e) {
                    return _vm.handleDebounceBookingCode(e.target.value);
                  }]
                }
              }, 'input', attributes, false), events))];
            }
          }], null, true),
          model: {
            value: _vm.pnrs,
            callback: function callback($$v) {
              _vm.pnrs = $$v;
            },
            expression: "pnrs"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.note.pnrs')) + " ")])], 1)];
      }
    }])
  })], 1), _vm._l(_vm.pnrPaxs, function (pax) {
    return _c('b-col', {
      key: pax.pnr,
      staticClass: "border-bottom mb-1",
      attrs: {
        "md": "12"
      }
    }, [_c('b-form-group', {
      scopedSlots: _vm._u([{
        key: "label",
        fn: function fn() {
          return [_c('h4', [_vm._v(" " + _vm._s(_vm.$t('saleReport.columns.bookingCode')) + " " + _vm._s(pax.pnr) + " "), _c('b-button', {
            staticClass: "p-50 rounded-circle",
            attrs: {
              "variant": "flat-danger"
            },
            on: {
              "click": function click($event) {
                return _vm.reloadPaxList(pax.pnr);
              }
            }
          }, [_c('IAmIcon', {
            attrs: {
              "icon": "revisionOutline",
              "size": "18"
            }
          })], 1)], 1)];
        },
        proxy: true
      }], null, true)
    }, _vm._l(pax.paxList, function (item, index) {
      return _c('b-row', {
        key: index
      }, [_c('b-col', {
        attrs: {
          "md": "2"
        }
      }, [_c('v-select', {
        staticClass: "bg-white",
        attrs: {
          "placeholder": _vm.$t('saleReport.columns.paxType'),
          "options": _vm.paxTypes,
          "clearable": false,
          "disabled": ""
        },
        scopedSlots: _vm._u([{
          key: "option",
          fn: function fn(data) {
            return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.' + data.label)) + " ")])];
          }
        }, {
          key: "selected-option",
          fn: function fn(data) {
            return [_c('span', [_vm._v(" " + _vm._s(_vm.$t('reservation.' + data.label)) + " ")])];
          }
        }, {
          key: "no-options",
          fn: function fn() {
            return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
          },
          proxy: true
        }], null, true),
        model: {
          value: item.paxType,
          callback: function callback($$v) {
            _vm.$set(item, "paxType", $$v);
          },
          expression: "item.paxType"
        }
      })], 1), _c('b-col', {
        attrs: {
          "md": "9"
        }
      }, [_c('b-row', [_c('b-col', {
        attrs: {
          "md": "6"
        }
      }, [_c('b-form-input', {
        staticClass: "bg-white w-30",
        attrs: {
          "placeholder": _vm.$t('customer.fullName'),
          "disabled": ""
        },
        on: {
          "input": function input($event) {
            item.paxName = _vm.formatterInput($event);
          }
        },
        model: {
          value: item.paxName,
          callback: function callback($$v) {
            _vm.$set(item, "paxName", $$v);
          },
          expression: "item.paxName"
        }
      })], 1), _c('b-col', {
        attrs: {
          "md": "3"
        }
      }, [_c('IAmInputMoney', {
        attrs: {
          "custom-class": "bg-white",
          "value-money": item.priceTicket,
          "rules": "required",
          "placeholder": _vm.$t('saleReport.columns.priceTicket')
        },
        on: {
          "update:valueMoney": function updateValueMoney($event) {
            return _vm.$set(item, "priceTicket", $event);
          },
          "update:value-money": function updateValueMoney($event) {
            return _vm.$set(item, "priceTicket", $event);
          }
        }
      })], 1), _c('b-col', {
        attrs: {
          "md": "3"
        }
      }, [_c('IAmInputMoney', {
        attrs: {
          "value-money": item.price,
          "placeholder": _vm.$t('resellTicket.form.price')
        },
        on: {
          "update:valueMoney": function updateValueMoney($event) {
            return _vm.$set(item, "price", $event);
          },
          "update:value-money": function updateValueMoney($event) {
            return _vm.$set(item, "price", $event);
          }
        }
      })], 1)], 1)], 1), _c('b-col', [_c('b-button', {
        staticStyle: {
          "padding": "4px"
        },
        attrs: {
          "id": "apply-all-price",
          "variant": "flat-info"
        },
        on: {
          "click": function click($event) {
            return _vm.handleApplyAll(item, pax.pnr);
          }
        }
      }, [_c('feather-icon', {
        staticClass: "cursor-pointer",
        attrs: {
          "icon": "CopyIcon",
          "size": "20"
        }
      })], 1), _c('b-tooltip', {
        attrs: {
          "target": "apply-all-price",
          "triggers": "hover focus",
          "boundary": "viewport",
          "variant": "info"
        }
      }, [_c('div', [_vm._v(_vm._s(_vm.$t('fee.applyAll')))])]), _c('b-button', {
        staticClass: "p-50 rounded-circle",
        attrs: {
          "id": "deletepax",
          "variant": "flat-danger"
        },
        on: {
          "click": function click($event) {
            return _vm.onDelete(index, pax.pnr);
          }
        }
      }, [_c('IAmIcon', {
        attrs: {
          "icon": "delete",
          "size": "18"
        }
      })], 1), _c('b-tooltip', {
        attrs: {
          "target": "deletepax",
          "triggers": "hover focus",
          "boundary": "viewport",
          "variant": "danger"
        }
      }, [_c('div', [_vm._v(_vm._s(_vm.$t('resellTicket.btn.delete')))])])], 1)], 1);
    }), 1)], 1);
  }), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('small', {
    staticClass: "text-danger"
  }, [_vm._v("(*) " + _vm._s(_vm.$t('resellTicket.note.paxName')) + ".")]), _vm._v(" "), _c('br'), _c('small', {
    staticClass: "text-danger"
  }, [_vm._v("(*) " + _vm._s(_vm.$t('resellTicket.note.price')) + " ")])]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": _vm.$t('resellTicket.form.phone'),
      "vid": "phone",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.phone')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          directives: [{
            name: "remove-non-numeric-chars",
            rawName: "v-remove-non-numeric-chars.allNumber",
            modifiers: {
              "allNumber": true
            }
          }],
          attrs: {
            "name": _vm.$t('resellTicket.form.phone'),
            "state": _vm.getValidationState(validationContext) === false ? false : null,
            "placeholder": _vm.$t('resellTicket.form.ph.phone')
          },
          model: {
            value: _vm.createRTReq.phone,
            callback: function callback($$v) {
              _vm.$set(_vm.createRTReq, "phone", $$v);
            },
            expression: "createRTReq.phone"
          }
        }), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")])], 1)];
      }
    }])
  })], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('validation-provider', {
    attrs: {
      "rules": "required",
      "name": _vm.$t('resellTicket.form.expiredDate'),
      "vid": "resellTicket-expiredDate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(validationContext) {
        return [_c('b-form-group', {
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('resellTicket.form.expiredDate')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("*")])];
            },
            proxy: true
          }], null, true)
        }, [_c('flat-pickr', {
          staticClass: "d-none",
          model: {
            value: _vm.createRTReq.expiredDate,
            callback: function callback($$v) {
              _vm.$set(_vm.createRTReq, "expiredDate", $$v);
            },
            expression: "createRTReq.expiredDate"
          }
        }), _c('div', {
          staticClass: "p-0 form-control",
          class: validationContext.errors[0] ? 'is-invalid' : ''
        }, [_c('flat-pickr', {
          attrs: {
            "id": "resellTicket-expiredDate",
            "name": _vm.$t('resellTicket.form.expiredDate'),
            "config": Object.assign({}, _vm.configFlatPickr, {
              altInputClass: 'form-control border-transparent px-50 py-0 height-fit'
            }),
            "placeholder": _vm.$t('topup.placeholderSelectDate')
          },
          model: {
            value: _vm.createRTReq.expiredDate,
            callback: function callback($$v) {
              _vm.$set(_vm.createRTReq, "expiredDate", $$v);
            },
            expression: "createRTReq.expiredDate"
          }
        })], 1), _c('b-form-invalid-feedback', [_vm._v(" " + _vm._s(validationContext.errors[0]) + " ")]), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v("Anh/Chị kiểm tra ngày hết hạn bảo lưu để chọn ngày hạn đăng bán phù hợp")])], 1)];
      }
    }])
  })], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('resellTicket.form.status'),
      "placeholder": _vm.$t('resellTicket.form.status')
    }
  }, [_c('b-form-checkbox', {
    staticClass: "mt-1",
    attrs: {
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.createRTReq.isActive,
      callback: function callback($$v) {
        _vm.$set(_vm.createRTReq, "isActive", $$v);
      },
      expression: "createRTReq.isActive"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('resellTicket.btn.pushlishNow')) + " ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('resellTicket.form.note'),
      "label-for": "note",
      "placeholder": _vm.$t('resellTicket.form.ph.note')
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "note"
    },
    model: {
      value: _vm.createRTReq.note,
      callback: function callback($$v) {
        _vm.$set(_vm.createRTReq, "note", $$v);
      },
      expression: "createRTReq.note"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('resellTicket.form.notePrivate'),
      "label-for": "note",
      "placeholder": _vm.$t('resellTicket.form.ph.notePrivate')
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "id": "notePrivate"
    },
    model: {
      value: _vm.createRTReq.notePrivate,
      callback: function callback($$v) {
        _vm.$set(_vm.createRTReq, "notePrivate", $$v);
      },
      expression: "createRTReq.notePrivate"
    }
  })], 1)], 1)], 2)], 1), _c('div', {
    staticClass: "d-flex-center gap-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "info"
    },
    on: {
      "click": _vm.handleSubmit
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('save')) + " ")]), _c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": _vm.handleCancel
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('cancel')) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }