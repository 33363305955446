import { ref, watch } from '@vue/composition-api'
import { isEqual, transform } from 'lodash'
import cloneDeep from 'lodash/cloneDeep'

import { apiSepay } from '@/api'

import useToast from '@useToast'

export default function useSepayHandle() {
  const { toastSuccess, toastError } = useToast()
  const filter = {
    perPage: 10,
    page: 1,
    a: null,
    status: null,
    transactionDate: null,
    startTransactionDate: null,
    endTransactionDate: null,
  }
  // Loading
  const loading = ref(true)
  const loadingForm = ref(true)

  const bankAccount = ref({})
  const bankAccounts = ref([])
  const totalBankAccount = ref(0)
  const filterBankAccount = ref(cloneDeep(filter))

  const company = ref({})
  const companies = ref([])
  const totalCompany = ref(0)
  const filterCompany = ref(cloneDeep(filter))
  const companyConfig = ref({})
  const companyCounter = ref({})
  const filterCompanyCounter = ref(cloneDeep(filter))
  const companyUpdate = ref({})
  const companyUpdateConfig = ref({})

  const transaction = ref({})
  const transactions = ref([])
  const totalTransaction = ref(0)
  const filterTransaction = ref(cloneDeep(filter))

  async function fetchBankAccounts(isClearFilter) {
    if (isClearFilter) {
      filterBankAccount.value = cloneDeep(filter)
    }
    try {
      loading.value = true
      const { data } = await apiSepay.getBankAccounts(filterBankAccount.value)
      bankAccounts.value = data.items
      totalBankAccount.value = data.count
      loading.value = false
    } catch (error) {
      toastError(error)
    }
  }

  async function fetchCompanies(isClearFilter) {
    if (isClearFilter) {
      filterCompany.value = cloneDeep(filter)
    }
    try {
      loading.value = true
      const { data } = await apiSepay.getCompanies(filterCompany.value)
      companies.value = data.items
      totalCompany.value = data.count
      loading.value = false
      return data.items
    } catch (error) {
      toastError(error)
      return null
    }
  }

  async function fetchTransactions(isClearFilter) {
    if (isClearFilter) {
      filterTransaction.value = cloneDeep(filter)
    }
    try {
      loading.value = true
      const { data } = await apiSepay.getTransactions(filterTransaction.value)
      transactions.value = data.items
      totalTransaction.value = data.count
      loading.value = false
      return data.items
    } catch (error) {
      toastError(error)
      return null
    }
  }

  async function fetchBankAccount(id) {
    try {
      const { data } = await apiSepay.fetchBankAccount(id)
      bankAccount.value = data
      return data
    } catch (error) {
      toastError(error)
      return null
    }
  }

  async function fetchCompany(id) {
    try {
      const { data } = await apiSepay.fetchCompany(id)
      company.value = data
      return data
    } catch (error) {
      toastError(error)
      return null
    }
  }

  async function fetchTransaction(id) {
    try {
      const { data } = await apiSepay.fetchTransaction(id)
      transaction.value = data
      return data
    } catch (error) {
      toastError(error)
      return null
    }
  }

  async function fetchCounterCompany(id) {
    try {
      const { data } = await apiSepay.counterCompany(
        id,
        filterCompanyCounter.value,
      )
      companyCounter.value = data
      return data
    } catch (error) {
      toastError(error)
      return null
    }
  }

  async function fetchConfigCompany(id) {
    try {
      const { data } = await apiSepay.getConfigurationCompany(id)
      companyConfig.value = data
      return data
    } catch (error) {
      toastError(error)
      return null
    }
  }

  async function updateConfigCompany(id) {
    try {
      const temp = transform(
        companyUpdateConfig.value,
        (result, value, key) => {
          if (!isEqual(value, companyConfig.value[key])) {
            result[key] = value
          }
        },
      )
      if (Object.keys(temp).length > 0 && temp.constructor === Object) {
        const { data } = await apiSepay.configurationCompany(id, temp)
        fetchCompanies()
        toastSuccess('sepay.configCompanySuccess')
        return data
      }
      return null
    } catch (error) {
      toastError(error)
      return null
    }
  }

  async function updateCompany(id) {
    try {
      const temp = transform(companyUpdate.value, (result, value, key) => {
        if (!isEqual(value, company.value[key])) {
          result[key] = value
        }
      })
      if (Object.keys(temp).length > 0 && temp.constructor === Object) {
        const { data } = await apiSepay.updateCompany(id, temp)
        fetchCompanies()
        toastSuccess('sepay.configCompanySuccess')
        return data
      }
      return null
    } catch (error) {
      toastError(error)
      return null
    }
  }

  watch(
    filterCompany,
    () => {
      fetchCompanies()
    },
    { deep: true },
  )

  watch(
    filterBankAccount,
    () => {
      fetchBankAccounts()
    },
    { deep: true },
  )

  watch(
    filterTransaction,
    () => {
      fetchTransactions()
    },
    { deep: true },
  )

  return {
    // Loading
    loading,
    loadingForm,
    bankAccount,
    bankAccounts,
    totalBankAccount,
    filterBankAccount,
    company,
    companies,
    totalCompany,
    filterCompany,
    companyConfig,
    companyCounter,
    filterCompanyCounter,
    companyUpdate,
    companyUpdateConfig,
    transaction,
    transactions,
    totalTransaction,
    filterTransaction,

    // Functions
    fetchBankAccounts,
    fetchCompanies,
    fetchTransactions,
    fetchBankAccount,
    fetchCompany,
    fetchTransaction,
    fetchCounterCompany,
    fetchConfigCompany,
    updateConfigCompany,
    updateCompany,
  }
}
