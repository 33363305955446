import { apiSaleReports, apiAgencies } from '@/api/'

export default {
  namespaced: true,
  // state: {},
  // getters: {},
  // mutations: {},
  actions: {
    fetchSaleReports(ctx, params) {
      return apiSaleReports.fetchSaleReports(params)
    },
    getSaleReportById(ctx, id) {
      return apiSaleReports.getSaleReportById(id)
    },

    fetchSaleReportsByAgency(ctx, params) {
      return apiSaleReports.fetchSaleReportsByAgency(params)
    },

    exportSaleReport(ctx, payload, fileName = 'Sale-Report') {
      return apiSaleReports.exportSaleReport(payload, fileName)
    },

    exportSaleReportTemplate(ctx, payload, fileName = 'Sale-Report-Template') {
      return apiSaleReports.exportSaleReportTemplate(payload, fileName)
    },
    fetchRefundTicket(ctx, params) {
      return apiSaleReports.fetchRefundTicket(params)
    },

    fetchAgenciesChild(ctx, id) {
      return apiAgencies.getChild(id)
    },

    createSaleReportManually(ctx, payload) {
      return apiSaleReports.bulkInsert({
        saleReports: [payload],
      })
    },

    updateSaleReport(ctx, payload) {
      return apiSaleReports.bulkUpdate({
        saleReports: [payload],
      })
    },

    deleteSaleReportManually(ctx, id) {
      return apiSaleReports.bulkDelete({
        ids: [id],
      })
    },

    // ANCHOR RETAILS
    fetchRetailSaleReports(ctx, params) {
      return apiSaleReports.fetchRetailSaleReports(params)
    },

  },
}
